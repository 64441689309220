.trash-icon-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}

.trash-icon {
  font-size: 1.5rem;
  color: #753838;
  cursor: pointer;
}

.text-red {
  color: rgb(218, 87, 87);
}
