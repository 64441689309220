.container {
    display: flex;
    flex-direction: column;
    height: 80vh;
}

.playground-window {
    flex-grow: 1;
    overflow-y: auto;
}

.playground-input-form {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

}

.playground-message-container.user {
  text-align: right;
}

.playground-message-container.system {
  text-align: left;
}

.playground-message.user {
  display: inline-block;
  background-color: #007bff;
  color: white;
  padding: 5px 10px;
  border-radius: 10px;
  position: relative;
}

.playground-message.system {
  display: inline-block;
  background-color: #f1f1f1;
  color: dark-grey;
  padding: 5px 10px;
  border-radius: 10px;
  position: relative;
}
