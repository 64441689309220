body {
    font-family: 'Inter', sans-serif;
}

.btn-primary {
    background-color: #7F2AD4FF !important;
    border-color: #7F2AD4FF !important;
}

.main-header {
    color: #01090D;
}

.nav-link {
    color: #5c5c5c !important;
}

.no-padding {
    padding: 0;
}

.no-gutters {
    margin-right: 0;
    margin-left: 0;
    > .col, > [class*="col-"] {
        padding-right: 0;
        padding-left: 0;
    }
}

.initial-prompt-textarea {
    height: 500px;
}

.cursor-pointer {
    cursor: pointer;
}

.google-drive-navigator-card {
    max-height: 300px;
}

.truncate-text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 3em;
    max-height: 3em;
}

.integration-data-source-icon {
    width: 100px;
}

.header-container {
  display: flex;
  align-items: center;
}

.header-title {
  margin-right: 8px;
}

.header-input {
  font-size: 2rem;
  font-weight: bold;
  border: none;
  outline: none;
  background-color: transparent;
}

.edit-icon {
  cursor: pointer;
}

.cards-scroll-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 150px); /* Adjust this value as needed */
  overflow-y: auto;
}

.cards-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  height: 100%;
}

.cards-container > * {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
  padding: 10px;
  box-sizing: border-box;
}

.file-upload-row {
    width: 100%;
}

.upload-button {
}

.file-info {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.truncate-card-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: calc(100% - 20px); /* Adjust to leave space for the delete button */
}

.action-card-header {
    display: flex;
    justify-content: space-between;
}

.subtitle {
    font-weight: bold;
}

.card-delete {
    background: none !important;
    border: none !important;
    padding: 0 !important;
    outline: none !important;
}

.card-delete:hover {
    background: none !important;
    border: none !important;
    padding: 0 !important;
}

.auto-width {
    width: auto;
}

.nd {
    text-decoration: none;
    color: #01090D;
}
