.homepage-card {
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
    width: calc(50% - 9px);
    min-width: 30vw;
    height: 266px;
    box-sizing: border-box;
    border-style: solid;
    border-width: 1px;
    background-color: #FDF8FF;
}

.homepage-card h1 {
    font-size: 32px;
    font-weight: bold;
}

.workflows-card {
    border-color: #5717A7 !important;
}

.chatbots-card {
    border-color: #4D4FD9 !important;
}

.usage-card {
    border-color: #C55D7E !important;
}

.account-details-card {
    border-color: #E95554 !important;
}

.homepage-card-table-header {
    font-size: 24px;
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.homepage-card-table-header :nth-child(0) {
    width: 40%;
}

.homepage-card-table-header :nth-child(1) {
    width: 34%;
}

.homepage-card-table-header :nth-child(2) {
    width: 26%;
}

.homepage-card-table tbody td {
    white-space: nowrap;
    text-overflow: ellipsis;
}