.sidebar-container {
  font-family: 'Inter', sans-serif;
  background: linear-gradient(#01090D 0%, #01090D 20%, #5717A7 80%, #7E55A1 100%);
}

.sidebar-logo {
  width: 220px;
  height: auto;
  margin: 18px auto 64px auto;
  display: block;
}

.sidebar-link {
  font-size: 18px;
  position: relative;
  display: inline-block;
}

.icon-style {
    width: 14px;
    height: 14px;
    fill: white;
}

.sidebar-item {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}

.sidebar-link:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: transparent;
  visibility: hidden;
}

.sidebar-link:hover:after {
  visibility: visible;
}

.larger-icon {
  font-size: 1.8em;
}

.medium-icon {
  font-size: 1.2em;
}

.align-icon {
  vertical-align: -12px;
}

/* Underline styles for active links */
.link-workflows.active,
.sidebar-link.link-workflows:hover:after {
  border-bottom: 2px solid #5717A7;
}

.link-chatbots.active,
.sidebar-link.link-chatbots:hover:after {
  border-bottom: 2px solid #3B9CEE;
}

.link-metrics.active,
.sidebar-link.link-metrics:hover:after {
  border-bottom: 2px solid #C55D7E;
}

.link-account.active,
.sidebar-link.link-account:hover:after {
  border-bottom: 2px solid #E95554;
}

.link-home.active,
.sidebar-link.link-home:hover:after {
  border-bottom: 2px solid #FDF8FF;
}

.link-integrations.active,
.sidebar-link.link-integrations:hover:after {
  border-bottom: 2px solid #EEAB80;
}