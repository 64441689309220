.label-style {
    font-weight: bold;
}

.form-box-style {
    border: 1px solid #89c7e4;
    padding: 5px;
    border-radius: 4px;
    background-color: #89c7e4;
}

/* Container for each field, to separate them into columns */
.field-container {
    display: flex;
    /* Use flexbox to align fields in columns */
    margin-bottom: 10px;
    /* Add some spacing between field containers */
    align-items: center;
    /* Vertically center align elements */
}

.button-margin-right {
    margin-right: 5px;
}