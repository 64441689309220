body {
    height: 100%;
    position: sticky;
}

.onboardingContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
    padding: 50px;
}

.onboardingCard {
    border: 1px solid #C55D7E;
    max-width: 600px;
    background-color: #FDF8FF;
    padding: 32px;
    border-radius: 8px;
}

.onboardingCard h2 {
    padding-bottom: 20px;
    font-weight: bold;
}

.onboardingCard .submitButton {
    text-align: right;
    padding-top: 20px;
}

.onboardingTitle {
    text-align: center;
    margin-bottom: 20px;
}